import { Group, IText } from "fabric/fabric-impl";
import { ISaveData } from "../../../state/contexts/SaveDataContext";
import { IBulletedList } from "fabric";

export default function getFontsInDocument(saveData: ISaveData) {
  const fontsInUse: string[] = [];
  saveData.pages.forEach((page) => {
    page.objects.forEach((obj) => {
      if (obj.type?.includes("text")) {
        const textObj = obj as IText;
        let family = textObj.fontFamily ?? "";
        // Check if this is one of our adobe fonts
        // @ts-ignore
        if (textObj.__fontFamily) {
          // @ts-ignore
          family = textObj.__fontFamily;
          textObj.fontFamily = family;
        }
        if (!fontsInUse.includes(family)) {
          fontsInUse.push(family);
        }
        if (textObj.styles && textObj.styles.length) {
          textObj.styles.forEach((style: any) => {
            if (style.style?.__fontFamily) {
              style.style.fontFamily = style.style.__fontFamily;
            }
            if (style.style?.fontFamily) {
              if (!fontsInUse.includes(style.style.fontFamily)) {
                fontsInUse.push(style.style.fontFamily);
              }
            }
          });
        }
      }
      if (obj.type === "group" && obj.name?.includes("bulletedList")) {
        const group = obj as IBulletedList;
        if (
          group.__fontSettings.fontFamily &&
          !fontsInUse.includes(group.__fontSettings.fontFamily)
        ) {
          fontsInUse.push(group.__fontSettings.fontFamily);
        }
      }
    });
  });
  return fontsInUse;
}
