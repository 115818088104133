import { Canvas, Group, Object as IObject } from "fabric/fabric-impl";
import asyncClone from "./asyncClone";
import generateGuid from "../../../../helpers/generateGuid";
import RESOLUTION from "../../../constants/RESOLUTION";
import textResizer from "./textResizer";
import { IBulletedList, Textbox } from "fabric";
import bulletResizer from "./bulletResizer";
import { createBleedClipPath } from "./createBackgroundClipPath";

export default async function duplicateObjects(
  objects: IObject[],
  createLayer: (
    id: string,
    layerName?: string | undefined,
    disableHistory?: boolean,
  ) => void,
  canvas: Canvas,
  inPlace?: boolean,
) {
  if (objects.length === 0) return [];

  if (objects.length === 1 && objects[0].type === "activeSelection") {
    objects = (objects[0] as Group)._objects;
  }
  canvas.discardActiveObject();

  const clones: IObject[] = [];
  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i];
    const clone = await asyncClone(obj);
    if (clone.name) {
      const namePrefix = clone.name.split("-")[0];
      clone.name = namePrefix + "-" + generateGuid();
      if (inPlace) {
        clone.top = obj.top;
        clone.left = obj.left;
      } else {
        clone.top = (obj.top ?? 0) + RESOLUTION / 4;
        clone.left = (obj.left ?? 0) + RESOLUTION / 4;
      }
      clones.push(clone);

      clone.clipPath = createBleedClipPath(canvas);

      canvas.add(clone);
      createLayer(clone.name, undefined, true);
      if (obj.type === "textbox") {
        textResizer(clone as Textbox);
      }
      if (obj.name?.includes("bulletedList")) {
        bulletResizer(clone as IBulletedList);
      }
    }
  }

  return clones;
}
