import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import DesignerSizes from "../../data/models/DesignerSizes";
import IHtmlVersion from "../../data/models/IHtmlVersion";
import useGetData from "../../hooks/dataFetchers/useGetData";
import CanvasDesigner from "../../postcard-designer/CanvasDesigner";
import LoadingScreen from "../../postcard-designer/features/DesignerInterface/LoadingScreen";
import ICanvasPageSettings from "../../postcard-designer/models/ICanvasPageSettings";
import StateProvider from "../../postcard-designer/state/StateProvider";
import LoadingWrapper from "../../shared/LoadingWrapper";
import Design from "../Designs/models/Design";
import { getProductType } from "../../data/models/DesignerProductTypes";

const EditDesignerDesign = ({
  mode = "admin",
  onSaveCallback,
}: {
  mode?: string;
  onSaveCallback?: (designID: number, envelopeType: string | undefined) => void;
}) => {
  const { designId } = useParams<{ designId: string }>();
  const { data, isLoading, error } = useGetData<{
    design: Design;
    version: IHtmlVersion;
  }>(`/api/designer/${designId}/edit`);

  const pages = useMemo(getPages, [data]);

  function getPages(): ICanvasPageSettings[] {
    if (!data) return [];
    const { design } = data;

    if (
      design.printPDFSource?.includes("PC") ||
      design.printPDFSource?.includes(DesignerSizes.BROCHURE)
    ) {
      return [{ name: "Front" }, { name: "Back" }];
    } else {
      return [{ name: "Page 1" }];
    }
  }
  if (isLoading) {
    return <LoadingScreen mode={mode} />;
  }

  return (
    <LoadingWrapper
      unwrapped
      loading={isLoading}
      hasError={error.hasError}
      errorMessage={error.errorMessage}
    >
      {data && (
        <React.Fragment>
          <StateProvider>
            <CanvasDesigner
              productType={getProductType(data.design.printPDFSource ?? "")}
              canvasWidth={data.design.width ?? 1}
              canvasHeight={data.design.height ?? 1}
              size={data.design.size}
              pages={pages}
              defaultCanvasData={
                data.version.json ? data.version.json : undefined
              }
              htmlVersionId={data.version.htmlVersionID}
              designId={designId}
              mode={mode}
              bAccountID={data.design.bAccountID ?? data.design.integrationID}
              onSaveCallback={onSaveCallback}
            />
          </StateProvider>
        </React.Fragment>
      )}
    </LoadingWrapper>
  );
};

export default EditDesignerDesign;
