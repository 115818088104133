import { Canvas, Object as IObject } from "fabric/fabric-impl";
import { ISafeAreaObject } from "../../../constants/Sizes";
import DesignerProductTypes from "../../../../data/models/DesignerProductTypes";
import getAddressBlockOverlay from "./getAddressBlockOverlay";
import { LetterFoldTypes } from "../../../state/slices/letterSettings";
import getBrochureOverlay from "./getBrochureOverlay";
import RESOLUTION from "../../../constants/RESOLUTION";
import { fabric } from "fabric";

export default async function insertOverlayObjects(
  canvas: Canvas,
  sizeInformation: ISafeAreaObject,
  page: number,
  productType: DesignerProductTypes,
  envelopeType?: string,
  foldType?: LetterFoldTypes,
) {
  const currentPageInformation = sizeInformation.pages?.find(
    (x) => x.pageNumber === page,
  );
  if (productType === DesignerProductTypes.LETTER && envelopeType) {
    const overlays = await getAddressBlockOverlay(envelopeType, canvas);
    if (overlays.length) {
      canvas.add(...overlays);
    }
    return;
  }
  if (!currentPageInformation) {
    return;
  }
  if (productType === DesignerProductTypes.BROCHURE && foldType) {
    const overlays = await getBrochureOverlay(
      foldType,
      canvas,
      currentPageInformation.hasAddressBlock,
    );
    if (overlays.length) {
      canvas.add(...overlays);
    }
    return;
  }
  if (currentPageInformation.hasAddressBlock) {
    const bleed = canvas.getObjects().find((x) => x.name === "bleed");
    if (bleed && bleed.width && bleed.height && bleed.left && bleed.top) {
      const objects: IObject[] = [];
      const addressBlockBackground = new fabric.Rect({
        width: sizeInformation.addressBlock.width * RESOLUTION - 2,
        height: sizeInformation.addressBlock.height * RESOLUTION - 2,
        strokeWidth: 2,
        stroke: "rgba(0,0,0,.6)",
        fill: "#FFFFFF",
        selectable: false,
        name: "overlay",
        left: bleed.left + sizeInformation.addressBlock.left * RESOLUTION,
        top: bleed.top + sizeInformation.addressBlock.top * RESOLUTION,
        // @ts-ignore
        __addressBlock: true,
      });
      objects.push(addressBlockBackground);
      const addressBlockCenter = addressBlockBackground.getCenterPoint();
      const addressBlockText = new fabric.Text("Address Block", {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 50,
        fill: "#000000",
        originX: "center",
        name: "overlay",
        originY: "center",
        left: addressBlockCenter.x,
        top: addressBlockCenter.y,
        selectable: false,
        evented: false,
        // @ts-ignore
        __addressBlock: true,
      });
      objects.push(addressBlockText);
      canvas.add(...objects);
      objects.forEach((object) => {
        object.bringToFront();
      });
    }
  }
}
