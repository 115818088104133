import {
  Cog6ToothIcon,
  EnvelopeIcon,
  HomeIcon,
  PhotoIcon,
  QrCodeIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import React from "react";
import theme from "../../../../helpers/theme";

export interface INavbarRoute {
  label: string;
  path: string;

  icon?: React.ReactFragment;
  children?: INavbarRoute[];
}

export const heroiconCss: React.CSSProperties = {
  color: theme.palette.primary.contrastText,
  height: "20px",
  width: "20px",
  fontWeight: 400,
  marginRight: "12px",
  textAlign: "center",
};

const routes: INavbarRoute[] = [
  {
    icon: <HomeIcon style={heroiconCss} />,
    label: "Overview",
    path: "/",
    children: [
      {
        label: "Batches",
        path: "/batches",
      },
      {
        label: "Orders",
        path: "/orders",
      },
    ],
  },
  {
    label: "Integrations",
    icon: <WebhookOutlinedIcon style={heroiconCss} />,
    path: "",
    children: [
      {
        label: "Webhooks",
        path: "/webhooks",
      },
      {
        label: "Zapier Guide",
        path: "https://www.pcmintegrations.com/zapierguide/",
      },
    ],
  },
  {
    label: "Designs",
    path: "",

    icon: <PhotoIcon style={heroiconCss} />,
    children: [
      {
        label: "HTML",
        path: "/designs/html",
      },
      {
        label: "Postcards",
        path: "/designs/postcards",
      },
      {
        label: "Letters",
        path: "/designs/letters",
      },
      {
        label: "Brochures",
        path: "/designs/brochures",
      },
      {
        label: "Greeting Cards",
        path: "/designs/greeting-cards",
      },
      {
        label: "Snap Aparts",
        path: "/designs/snap-aparts",
      },
      {
        label: "Design Gallery",
        path: "/gallery",
      },
      {
        label: "New Design",
        path: "/designs/create",
      },
      {
        label: "Place an Order",
        path: "/designs/place-an-order",
      },
    ],
  },
  {
    label: "Envelopes",
    icon: <EnvelopeIcon style={heroiconCss} />,
    path: "/envelopes",
  },
  {
    label: "QR Codes",
    icon: <QrCodeIcon style={heroiconCss} />,
    path: "/qr-codes",
  },
  {
    label: "Settings",
    path: "",

    icon: <Cog6ToothIcon style={heroiconCss} />,
    children: [
      {
        label: "Info & Billing",
        path: "/settings/account-and-billing",
      },
      {
        label: "Mailing Settings",
        path: "/settings/mailing-settings",
      },
      {
        label: "API Keys",
        path: "/settings/api-keys",
      },
    ],
  },
  {
    label: "Help",
    path: "",

    icon: <QuestionMarkCircleIcon style={heroiconCss} />,
    children: [
      {
        label: "Documentation",
        path: "https://docs.pcmintegrations.com/docs/directmail-api/84aca6606cef4-direct-mail-api-v3",
      },
      {
        label: "Contact Us",
        path: "/contact-us",
      },
    ],
  },
];

export default routes;
