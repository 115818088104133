import tw, { css } from "twin.macro";

import useTools, { ToolbarContext } from "../../hooks/useTools";

import React, { useContext, useEffect, useRef, useState } from "react";
import { LeftSidebarContent } from "./SidebarContent";

import { Tool } from "../../state/models/ICanvasTool";
import SidebarContainer from "./Sidebar/SidebarContainer";
import SidebarTitle from "./Sidebar/SidebarTitle";

import useLayers from "../../hooks/useLayers";
import Images from "./Images/Images";
import Properties from "./Properties/Properties";
import ToolSidebar from "./ToolMenu/ToolSidebar";

import { Shape } from "../../state/models/IToolSettings";

import Shapes from "./Shapes/Shapes";
import TextSidebar from "./Text/TextSidebar";

import TopToolbar from "./TopToolbar/TopToolbar";

import { IEvent } from "fabric/fabric-impl";
import usePenTool from "../../hooks/usePenTool/usePenTool";
import { CanvasContext } from "../../state/contexts/CanvasContext";
import {
  SelectedObjectContext,
  SelectedObjectDispatchContext,
} from "../../state/contexts/SelectedObjectContext";
import { selectDesignInformation } from "../../state/slices/designInformation";
import { useDesignerSelector } from "../../state/store";
import QrCodeSidebar from "../QrCodes/QrCodeSidebar";
import LayersWrapper from "./Layers/LayersWrapper";
import PageSelector from "./PageSelector/PageSelector";
import { setCurrentTool } from "../../state/slices/toolSettings";

const styles = {
  wrapper: [
    tw`h-full flex bg-opacity-0 w-full`,
    css`
      max-width: 100%;
    `,
  ],
  invertedButton: [
    tw`text-blue px-4 h-full hover:bg-hover cursor-pointer font-semibold uppercase`,
  ],
  activeButton: [
    tw`text-blue px-4 h-full bg-hover cursor-pointer font-semibold uppercase`,
  ],
  button: [
    tw`text-glacier bg-blue px-6 h-full  cursor-pointer font-semibold hover:bg-opacity-80 uppercase`,
  ],
  settingsButton: [
    tw`flex flex-col items-center justify-center hover:bg-hover h-full px-4`,
  ],
  settingsIcon: [tw`w-4 h-4 fill-labels`],
  navigation: [
    tw`absolute top-20 right-4 flex items-center`,
    css`
      z-index: 25;
    `,
  ],
  navigationButton: (isActive: boolean, disabled = false) => [
    tw`bg-glacier border-border border-solid border border-r-0  text-labels last:border-r hover:bg-border py-2.5 px-2`,
    css`
      font-size: 18px;
    `,
    isActive && tw`bg-blue text-white hover:bg-opacity-80 hover:bg-blue`,
    disabled &&
      tw`bg-buttonDisabled hover:bg-buttonDisabled text-buttonTextDisabled`,
  ],
};

interface ITopToolbarProps {
  children: React.ReactFragment;
  toggleShortcuts: (disabled: boolean | string) => void;
  mode?: string;
  onSaveCallback?: (designID: number, envelopeType: string | undefined) => void;
  forceDisabled?: boolean;
}

const Toolbars = ({
  children,
  toggleShortcuts,
  mode = "internal",
  onSaveCallback,
  forceDisabled = false,
}: ITopToolbarProps) => {
  const tools = useTools();
  const canvas = useContext(CanvasContext);
  const { currentTool, changeCurrentTool, updateToolSetting } = tools;
  const { object: selectedObject } = useContext(SelectedObjectContext);
  const { updateSelectedObject } = useContext(SelectedObjectDispatchContext);
  const { selectedPage, changePage, addPage, deletePage } = useLayers();
  const [layersOpen, setLayersOpen] = useState(true);
  const penTool = usePenTool();
  const designInformation = useDesignerSelector(selectDesignInformation);
  const [leftSidebar, setLeftSidebar] = useState({
    isOpen: false,
    sidebarContent: LeftSidebarContent.shapes,
  });
  const [fontsOpen, setFontsOpen] = useState(false);
  function toggleFontSidebar() {
    setFontsOpen(!fontsOpen);
  }

  function handleToolChange(tool: Tool, shape?: Shape) {
    if (tool === Tool.image) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.images,
      });
    } else if (tool === Tool.layers) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.layers,
      });
      changeCurrentTool(Tool.layers);
    } else if (tool === Tool.pen) {
      setLeftSidebar({
        isOpen: false,
        sidebarContent: LeftSidebarContent.shapes,
      });
    } else if (tool === Tool.qrCode) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.qrCode,
      });
    }
    if (tool === Tool.shape && !shape) {
      updateToolSetting("shape", Shape.svg);
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.shapes,
      });
    } else if (tool === Tool.shape) {
      updateToolSetting("shape", shape);
    }

    changeCurrentTool(tool);
  }

  function handleLeftSidebarClose() {
    changeCurrentTool(Tool.select);
    setLeftSidebar({
      sidebarContent: LeftSidebarContent.shapes,
      isOpen: false,
    });
  }

  function getLeftSidebar() {
    if (
      selectedObject &&
      leftSidebar.isOpen &&
      leftSidebar.sidebarContent === LeftSidebarContent.toolSettings
    )
      return (
        <Properties
          selectedObject={selectedObject}
          fontsOpen={fontsOpen}
          closeFonts={() => setFontsOpen(false)}
        />
      );
    switch (leftSidebar.sidebarContent) {
      // case LeftSidebarContent.toolSettings: {
      //   return (
      //     <Properties
      //       selectedObject={selectedObject}
      //       fontsOpen={fontsOpen}
      //       closeFonts={() => setFontsOpen(false)}

      //     />
      //   );
      // }

      case LeftSidebarContent.shapes: {
        return <Shapes />;
      }
      case LeftSidebarContent.text: {
        return <TextSidebar />;
      }
      case LeftSidebarContent.images: {
        return <Images />;
      }
      case LeftSidebarContent.layers: {
        return (
          <LayersWrapper
            onClose={() => {
              setLeftSidebar({
                isOpen: false,
                sidebarContent: LeftSidebarContent.shapes,
              });
              changeCurrentTool(Tool.select);
            }}
            sidebarIsOpen={leftSidebar.isOpen}
            disabled={forceDisabled ? true : penToolActive}
          />
        );
      }
      case LeftSidebarContent.qrCode: {
        return <QrCodeSidebar />;
      }
      default:
        return <div />;
    }
  }

  function setupSelectionListeners() {
    if (!canvas) return;
    canvas.on("selection:created", handleSelectionChange);
    canvas.on("selection:cleared", handleSelectionChange);
    canvas.on("selection:updated", handleSelectionChange);

    return () => {
      if (!canvas) return;
      canvas.off("selection:created", handleSelectionChange);
      canvas.off("selection:cleared", handleSelectionChange);
      canvas.off("selection:updated", handleSelectionChange);
    };
  }
  function clearSelectedObject() {
    updateSelectedObject();
  }

  function handleSelectionChange(e: IEvent) {
    if (!canvas) return;

    const selected = canvas.getActiveObject();

    if (selected) {
      updateSelectedObject(selected);
    } else {
      clearSelectedObject();
    }
  }

  useEffect(() => {
    if (
      selectedObject &&
      leftSidebar.isOpen &&
      leftSidebar.sidebarContent === LeftSidebarContent.toolSettings
    ) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.toolSettings,
      });
    } else if (currentTool === Tool.image) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.images,
      });
    } else if (currentTool === Tool.text) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.text,
      });
    } else if (
      currentTool === Tool.shape &&
      tools.toolSettings.shape === Shape.svg
    ) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.shapes,
      });
    } else if (currentTool === Tool.qrCode) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.qrCode,
      });
    } else if (currentTool === Tool.layers) {
      setLeftSidebar({
        isOpen: true,
        sidebarContent: LeftSidebarContent.layers,
      });
    } else {
      setLeftSidebar({
        isOpen: false,
        sidebarContent: LeftSidebarContent.shapes,
      });
    }
  }, [selectedObject, currentTool]);

  useEffect(setupSelectionListeners, [canvas]);

  const penToolRef = useRef(false);
  const penToolActive = penTool.penToolActive;

  function handlePenToolCleanup() {
    if (penToolRef.current === false && penToolActive === false) return;
    if (penToolRef.current === true && penToolActive === true) return;
    penToolRef.current = penToolActive;
    if (!penToolActive) {
      toggleShortcuts(false);
    } else {
      toggleShortcuts(true);
    }
  }

  useEffect(handlePenToolCleanup, [penToolActive]);

  return (
    <ToolbarContext.Provider value={tools}>
      <div css={styles.wrapper}>
        <PageSelector />
        <TopToolbar
          sidebarIsOpen={leftSidebar.isOpen}
          selectedObject={selectedObject}
          openFonts={() => setFontsOpen(true)}
          toggleShortcuts={toggleShortcuts}
          penTool={penTool}
          mode={mode}
          onSaveCallback={onSaveCallback}
          forceDisabled={forceDisabled}
          openAdvancedProperties={() => {
            setLeftSidebar({
              sidebarContent: LeftSidebarContent.toolSettings,
              isOpen: true,
            });
            changeCurrentTool(Tool.select);
          }}
          advancedPropertiesOpen={
            leftSidebar.sidebarContent === LeftSidebarContent.toolSettings &&
            leftSidebar.isOpen
          }
        />
        <ToolSidebar
          currentTool={currentTool}
          onChange={handleToolChange}
          currentShape={tools.toolSettings.shape}
          layersActive={layersOpen}
          penTool={penTool}
          forceDisabled={forceDisabled}
        />

        <div tw="relative w-full flex flex-col h-full">
          <div css={styles.navigation}>
            {/* {designInformation?.canvasSettings?.height !== 11 &&
              designInformation?.canvasSettings?.width !== 8.5 && (
                <React.Fragment>
                  <ToolButton
                    position="left"
                    onClick={() => changePage("Front")}
                    css={styles.navigationButton(
                      selectedPage === "Front",
                      penToolActive
                    )}
                    disabled={forceDisabled ? true : penToolActive}
                  >
                    Front
                  </ToolButton>
                  <ToolButton
                    position="right"
                    onClick={() => changePage("Back")}
                    css={styles.navigationButton(
                      selectedPage === "Back",
                      penToolActive
                    )}
                    disabled={forceDisabled ? true : penToolActive}
                  >
                    Back
                  </ToolButton>
                </React.Fragment>
              )} */}
          </div>

          <SidebarContainer isOpen={leftSidebar.isOpen} position="left">
            {leftSidebar.sidebarContent !== LeftSidebarContent.layers &&
              currentTool !== Tool.layers && (
                <SidebarTitle onClose={handleLeftSidebarClose}>
                  {fontsOpen ? "Fonts" : leftSidebar.sidebarContent}
                </SidebarTitle>
              )}
            {getLeftSidebar()}
          </SidebarContainer>

          <div
            css={[
              tw`h-full flex flex-grow-0`,
              css`
                width: 100%;
                max-width: 100%;
              `,
            ]}
            className="_canvasWrapper"
          >
            {children}
          </div>
        </div>
      </div>
    </ToolbarContext.Provider>
  );
};

export default Toolbars;
