import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Tool } from "../models/ICanvasTool";
import IDesignInformation from "../models/IDesignInformation";
import { DesignerState } from "../store";
import DesignerProductTypes from "../../../data/models/DesignerProductTypes";

const initialState: IDesignInformation = {
  productType: DesignerProductTypes.POSTCARD,
};

export const designInformationSlice = createSlice({
  name: "designInformation",
  initialState: initialState,
  reducers: {
    setDesignInformation: (
      state,
      action: PayloadAction<Partial<IDesignInformation>>,
    ) => {
      if (action.payload.canvasSettings) {
        state.canvasSettings = { ...action.payload.canvasSettings };
      }
      if (action.payload.pageSettings) {
        state.pageSettings = [...action.payload.pageSettings];
      }
      if (action.payload.htmlVersionID) {
        state.htmlVersionID = action.payload.htmlVersionID;
      }
      if (action.payload.designID) {
        state.designID = action.payload.designID;
      }
      if (action.payload.bAccountID) {
        state.bAccountID = action.payload.bAccountID;
      }
      if (action.payload.initialJson) {
        state.initialJson = action.payload.initialJson;
      }
      if (action.payload.productType) {
        state.productType = action.payload.productType;
      }
    },
    resetDesignInformation: () => initialState,
  },
});

export const { setDesignInformation, resetDesignInformation } =
  designInformationSlice.actions;
export const selectDesignInformation = (state: DesignerState) =>
  state.designInformation;
export default designInformationSlice.reducer;
