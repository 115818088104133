import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchClient } from "../../../../helpers/fetchClient";
import { sortAlphabetically } from "../../../../helpers/sortHelpers";
import { DesignSize } from "../../../Admin/models/DesignCreationModel";

interface SizeSelectorProps {
  isPortal?: boolean;
  source?: string | null;
  disabled?: boolean;
  size: DesignSize;
  onChange: (size: DesignSize) => void;
}

const SizeSelector: React.FC<SizeSelectorProps> = ({
  isPortal = true,
  source,
  disabled = false,
  size,
  onChange,
}) => {
  const [sizes, setSizes] = useState<DesignSize[]>([]);

  function onSizesLoaded(resSizes: DesignSize[]) {
    setSizes(
      resSizes
        .sort((a, b) => sortAlphabetically(a.sizeInfoSize, b.sizeInfoSize))
        .filter((x) => {
          if (!isPortal) {
            if (!source || source === "sf") {
              // Hide BRO and Letters for SF integration.
              return !["811", "BRO"].includes(x.sizeInfoSize);
            } else {
              // Hide BRO for all other integrations.
              return !["BRO"].includes(x.sizeInfoSize);
            }
          }
          return true;
        }),
    );
  }

  function initializeSizes() {
    fetchClient.get({
      path: "/designs/design-sizes",
      onSuccess: onSizesLoaded,
    });
  }

  function handleChange(e: SelectChangeEvent) {
    const dSize = [
      ...sizes,
      {
        sizeInfoSize: "811SNP",
        sizeLabel: "Snap Apart",
        inventoryCD: "811SNP",
      },
      {
        sizeInfoSize: "GRC",
        sizeLabel: "Greeting Card",
        inventoryCD: "GRC",
      },
    ].find((x) => x.sizeInfoSize === e.target.value);
    if (dSize) onChange(dSize);
  }

  useEffect(initializeSizes, []);

  return (
    <FormControl fullWidth>
      <InputLabel>Design Size</InputLabel>
      <Select
        value={size.sizeInfoSize}
        label="Design Size"
        onChange={handleChange}
        disabled={disabled}
      >
        <MenuItem value="" disabled>
          Select a size
        </MenuItem>
        {sizes.map((size) => (
          <MenuItem key={size.sizeInfoSize} value={size.sizeInfoSize}>
            {size.sizeLabel}
          </MenuItem>
        ))}
        {isPortal && sizes.length > 0 && (
          <MenuItem value="811SNP">Snap Apart</MenuItem>
        )}
        {isPortal && sizes.length > 0 && (
          <MenuItem value="GRC">Greeting Card</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SizeSelector;
